<template>
	<b-row>
		<h1>About Site</h1>
	</b-row>
</template>

<script>
// @ is an alias to /src

export default {
	name: 'AboutSiteView',
	components: {
	}
}
</script>
