<template>
	<b-row>
		<b-col>
				<b-row class="my-auto w-100">
					<b-col class="text-center col-12">
						<h1>Brendon Dugan</h1>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-img :src="require('@/assets/images/smile_new.jpg')" alt="Brendon Dugan Headshot" thumbnail rounded fluid />
					</b-col>
				</b-row>
		</b-col>
	</b-row>
</template>

<script>
export default {
  name: 'HomeView'
}
</script>
