<template>
	<b-row>
    <b-col v-if="workEntriesLoaded === false || skillsLoaded === false">
      <b-row class="my-auto w-100">
        <b-col class="text-center col-12">
          <h1>Loading: Your Patience Is Appreciated</h1>
          <p>
            Cost management in software development is an important consideration for any project. In order to keep the
            costs for my relatively low traffic website down, I have made use of GCP's CloudRun service to automatically
            spin down the backend service for this website whenever it is not receiving traffic. While this is excellent
            for cost management, it does result in longer than average response times for those who are viewing this site
            when the backend is spun down. Given the nature of this site, that seems like a reasonable compromise. On
            most browsers this notice should be replaced with my CV data in a moment, but if it hasn't been feel free
            to give it a refresh or two and the data should be there momentarily.
          </p>
        </b-col>
      </b-row>
    </b-col>
		<b-col v-else>
			<b-row class="my-auto w-100">
				<b-col class="text-center col-12">
					<h1>Brendon Dugan</h1>
					<p>A Software Engineer with a Knack for System Design</p>
				</b-col>
			</b-row>
			<b-row cols="1" cols-md="2" class="my-auto">
				<b-col>
					<b-img :src="require('@/assets/images/neutral_new.jpg')" alt="Brendon Dugan Headshot" thumbnail rounded fluid class="mx-auto" />
				</b-col>
				<b-col>
					<b-row>
						<b-col class="text-center" >
							<h3>Online Presence</h3>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-row cols="2">
								<b-col>
									<span class="float-right">Stack Exchange</span>
								</b-col>
								<b-col>
									<a href="http://stackexchange.com/users/467572/brendon-dugan">brendon-dugan</a>
								</b-col>
							</b-row>
							<b-row cols="2">
								<b-col>
									<span class="float-right">Github</span>
								</b-col>
								<b-col>
									<a href="https://github.com/brennydoogles">brennydoogles</a>
								</b-col>
							</b-row>
							<b-row cols="2">
								<b-col>
									<span class="float-right">Dream.In.Code</span>
								</b-col>
								<b-col>
									<a href="http://www.dreamincode.net/forums/user/240257-brennydoogles/">brennydoogles</a>
								</b-col>
							</b-row>
							<b-row cols="2">
								<b-col>
									<span class="float-right">LinkedIn</span>
								</b-col>
								<b-col>
									<a href="http://www.linkedin.com/in/brendondugan">brendondugan</a>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-row class="my-auto">
				<b-col>
					<b-row>
						<b-col>
							<h2>About</h2>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<p>
								Brendon Dugan is a polyglot Software Engineer with a varied background.
								With experience in fields as varied as Hospitality, eCommerce, Logistics, and Medical Devices, Brendon is experienced in applying
								generalized knowledge effectively to a variety of complex problems. While System Design
								is Brendon's passion, he finds himself content in any situation in which he is surrounded by
								intelligent professionals who enjoy the free exchange of knowledge and ideas. As a dual American/British
                Citizen Brendon has the unrestricted right to work in either country without the need for Visa sponsorship.
							</p>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-row v-if="skills.length > 0" class="my-auto">
				<b-col>
					<SkillsSectionComponent/>
				</b-col>
			</b-row>
			<b-row v-if="workEntries.length > 0" class="my-auto">
				<b-col>
					<b-row>
						<b-col>
							<h2>Work Experience</h2>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<WorkEntry v-for="entry in this.workEntries" :work-entry="entry" :key="entry.experienceId"/>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
      <b-row class="onlyprint my-auto w-100">
        <b-col class="text-center col-12">
          <p>The most up to date version of this document is always available at <a href="https://brendondugan.com/cv">brendondugan.com/cv</a></p>
        </b-col>
      </b-row>
		</b-col>
	</b-row>
</template>

<script>
import {mapState} from "vuex";
import WorkEntry from "@/components/WorkEntry";
import SkillsSectionComponent from "@/components/SkillsSectionComponent";

export default {
  name: "CVView",
  components: {SkillsSectionComponent, WorkEntry},
  data () {
    return {
      timer: ''
    }
  },
  computed: mapState([
    'workEntries',
    'skills',
    'workEntriesLoaded',
    'skillsLoaded'
  ]),
  created() {
    this.fetchCVData();
    this.timer = setInterval(this.fetchCVData, 10000);
  },
  methods: {
    fetchCVData (){
      this.$store.dispatch('fetchWorkEntries');
      this.$store.dispatch('fetchSkills');
      if(this.workEntriesLoaded && this.skillsLoaded){
        this.endAutoUpdate();
      }
    },
    endAutoUpdate() {
      clearInterval(this.timer);
    }
  },
  beforeUnmount() {
    this.endAutoUpdate();
  }
}
</script>

<style scoped>

</style>