<template>
	<b-container>
		<b-row class="noprint">
			<b-col>
				<b-img src='/bd-favicon64.png' alt="BD Logo" />
			</b-col>
			<b-col class="my-auto">
				<b-nav pills align="right">
					<b-nav-item to="/">Home</b-nav-item>
					<b-nav-item to="/music">Music</b-nav-item>
					<b-nav-item to="/cv">CV</b-nav-item>
					<b-nav-item to="/about/me">About Me</b-nav-item>
<!--					<b-nav-item to="/about/site">About This Site</b-nav-item>-->
				</b-nav>
			</b-col>
		</b-row>
		<hr class="noprint" />
		<b-row class="my-auto">
			<b-col id="content">
				<router-view/>
			</b-col>
		</b-row>
		<hr class="noprint" />
		<b-row class="noprint">
			<b-col class="w-100 my-auto">
				<p class="text-right">Copyright {{new Date().getFullYear()}} Brendon Dugan</p>
			</b-col>
		</b-row>
	</b-container>
</template>

<style lang="scss">
#container{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 30px;
	text-align: center;
}
</style>

<script>
export default {
	name: 'App',
	watch: {
		$route: {
			immediate: true,
			handler(to) {
				document.title = to.meta.title || 'BD';
			}
		},
	},
};
</script>