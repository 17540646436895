<template>
	<b-row class="workEntry card card-body bg-light">
		<b-row>
			<b-col><span class="h3">{{ workEntry.companyName }}</span> - <span class="h6">{{ workEntry.jobTitle }}</span></b-col>
		</b-row>
		<b-row>
			<b-col>
				{{toDateString(this.workEntry.startDate)}} - {{toDateString(this.workEntry.endDate)}}
			</b-col>
		</b-row>
		<b-row>
			<SkillListComponent section-header="Skills Used" :skills="workEntry.skills" />
			<AnnotatedStringListComponent section-header="Responsibilities" :list-items="workEntry.responsibilities" />
		</b-row>
		<b-row v-if="hasAchievements">
			<AnnotatedStringListComponent section-header="Notable Projects and Achievements" :list-items="workEntry.achievements" />
		</b-row>
	</b-row>
</template>

<script>


import AnnotatedStringListComponent from "@/components/AnnotatedStringListComponent";
import SkillListComponent from "@/components/SkillListComponent";
export default {
	name: "WorkEntry",
	components: {SkillListComponent, AnnotatedStringListComponent},
	props: {
		workEntry: Object,

	},
	computed: {
		hasAchievements(){
			return this.workEntry.achievements.length > 0;
		}
	},
	methods: {
		toDateString(dateString){
			if(dateString == null || dateString === "null"){
				return "Present"
			}
			const date = new Date(dateString);
			return date.toLocaleDateString('en-us', { month: "long", year: "numeric"})
		}
	}
}
</script>

<style lang="scss" scoped>
	workEntry{
		margin: 50px;
		h3{
			display: inline;
		}
	}

</style>