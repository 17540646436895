<template>
  <b-row>
		<b-col>
			<b-row class="my-auto w-100">
				<b-col class="text-center col-12">
					<h1>About Brendon</h1>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<p>
						Brendon Dugan is a software engineer based out of Broadstairs, Kent, UK.
						When he is not writing code he is often playing guitar or mandolin, reading, exploring, or
						browsing Imgur. While no longer actively maintained, Brendon has a <a
							href="http://brennydoogles.wordpress.com/">Programming Blog</a> through
						which he has imparted a small subset of the things he has learned during his career.
					</p>
				</b-col>
			</b-row>
		</b-col>
  </b-row>
</template>

<script>
// @ is an alias to /src

export default {
	name: 'AboutMeView',
	components: {
	}
}
</script>
