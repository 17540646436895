<template>
	<b-row>
		<b-col>
			<b-row class="my-auto w-100">
				<b-col class="text-center col-12">
					<h1>Music</h1>
				</b-col>
			</b-row>
			<b-row cols="1" cols-md="2">
					<YoutubeVideoComponent
							description="Brendon Dugan covers &quot;Wonderwall&quot; by Oasis"
							video-url="https://www.youtube.com/embed/xSa3otLqm94"
					/>
				<YoutubeVideoComponent
						description="Brendon Dugan covers &quot;Another New World&quot; by The Punch Brothers"
						video-url="https://www.youtube.com/embed/BoQjeC7wzGQ"
				/>
				<YoutubeVideoComponent
						description="Brendon Dugan and Michael Vire cover &quot;In My Life&quot; by The Beatles"
						video-url="https://www.youtube.com/embed/D8dUh9mwgaE"
				/>
				<YoutubeVideoComponent
						description="Brendon Dugan covers &quot;Eleanor Rigby&quot; by The Beatles"
						video-url="https://www.youtube.com/embed/dACxXxezvbI"
				/>
			</b-row>
		</b-col>
	</b-row>
</template>

<script>
import YoutubeVideoComponent from "@/components/YoutubeVideoComponent";
export default {
	name: "MusicView",
	components: {
		YoutubeVideoComponent
	}
}
</script>