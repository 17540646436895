<template>
	<b-col class="mx-auto justify-content-center">
		<b-row>
			<span class="sectionHeader w-100 text-center">{{ sectionHeader }}</span>
		</b-row>
		<b-row>
			<ul>
				<li v-for="item in state.items" v-bind:key="item">
					<span v-html="item"/>
				</li>
			</ul>
		</b-row>
		<b-row>
			<ul :v-if="state.textAnnotations.length > 0">
				<li v-for="textAnnotation in state.textAnnotations" v-bind:key="textAnnotation" class="textAnnotationList">
					<span class="textAnnotation" v-html="textAnnotation"/>
				</li>
			</ul>
		</b-row>
	</b-col>
</template>

<script>
export default {
	name: "AnnotatedStringListComponent",
	props: {
		sectionHeader: String,
		listItems: Array,
	},
	data: function () {
		return {
			state: {
				items: [],
				textAnnotations: []
			}
		}
	},
	computed: {

	},
	beforeMount() {
		this.listItems.forEach(item => {
			var rawText = item.rawContent;
			if(!item.hasAnnotations){
				this.state.items.push(rawText);
			}
			else{
				item.annotations.forEach(annotation => {
					if (annotation.type === ".dto.TextAnnotationDTO") {
						let annotationNumber = this.saveTextAnnotation(annotation.annotationText)
						rawText = rawText + "<sup>" + annotationNumber + "</sup>";
					}
					if (annotation.type === ".dto.LinkAnnotationDTO") {
						let linkText = "<a href=\"" + annotation.linkTarget + "\" target=\"_blank\">" + annotation.replacementText + "</a>";
						rawText = rawText.replace(annotation.placeHolderText, linkText);
					}
				});
				this.state.items.push(rawText);
			}
		});
	},
	methods: {
		saveTextAnnotation(annotationText){
			const annotationNumber = this.state.textAnnotations.length + 1;
			const annotationFullText = "<sup>" + annotationNumber + "</sup>" + annotationText;
			this.state.textAnnotations.push(annotationFullText);
			return annotationNumber;
		}
	}
}
</script>

<style scoped>

</style>