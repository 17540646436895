<template>
	<b-col class="skillListContainer">
		<b-row ><span class="sectionHeader w-75 text-center">{{sectionHeader}}</span></b-row>
		<b-row>
			<ul class="skillList">
				<li v-for="skill in skills" v-bind:key="skill.skillName" class="border rounded">{{skill.skillName}}</li>
			</ul>
		</b-row>
	</b-col>
</template>

<script>
export default {
	name: "SkillListComponent",
	props: {
		sectionHeader: String,
		skills: Array,
	}
}
</script>

<style lang="scss" scoped>

</style>