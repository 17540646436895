<template>
		<b-col>
			<b-row>
				<b-col>
					<b-embed
							type="iframe"
							aspect="16by9"
							:src="videoUrl"
							allowfullscreen
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<p class="text-center">{{description}}</p>
				</b-col>
			</b-row>
		</b-col>
</template>

<script>
export default {
	name: "YoutubeVideoComponent",
	props: {
		description: String,
		videoUrl: String
	}
}
</script>

