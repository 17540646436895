<template>
	<b-row class="skillSection">
		<b-col>
      <b-row>
        <b-col>
          <h2>Skills</h2>
        </b-col>
      </b-row>
			<b-row>
				<b-col class="col-6">
					<SkillListComponent section-header="Programming Languages" :skills="state.languages"/>
				</b-col>
				<b-col class="col-6">
					<SkillListComponent section-header="Technologies" :skills="state.technologies"/>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="col-6">
					<SkillListComponent section-header="Methodologies & Design Patterns" :skills="state.methodologies"/>
				</b-col>
				<b-col class="col-6">
					<SkillListComponent section-header="Other Skills" :skills="state.other"/>
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>

<script>
import SkillListComponent from "@/components/SkillListComponent";
import {mapState} from "vuex";
export default {
	name: "SkillsSectionComponent",
	components: {SkillListComponent},
	computed: mapState([
		'skills'
	]),
	data: function () {
		return {
			state: {
				languages: [],
				technologies: [],
				methodologies: [],
				other: []
			}
		}
	},
	beforeMount() {
		this.skills.forEach(skill => {
			switch (skill.skillType) {
				case 'LANGUAGE':
					this.state.languages.push(skill);
					break;
				case 'TECHNOLOGY':
					this.state.technologies.push(skill);
					break;
				case 'METHODOLOGY':
					this.state.methodologies.push(skill);
					break;
				case 'OTHER':
				default:
					this.state.other.push(skill);
					break;

			}
		});
	}
}
</script>

<style scoped>

</style>