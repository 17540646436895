import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import axiosRetry from "axios-retry";

Vue.use(Vuex)

const apiHost = process.env.VUE_APP_API_BASE_URL + "/api"
const client = axios.create(
    {
        baseURL: apiHost,
        timeout: 6000
    }
)
axiosRetry(
    client,
    {
        retries: 10,
        retryDelay: axiosRetry.exponentialDelay,
        shouldResetTimeout: true,
        retryCondition: axiosRetry.isNetworkOrIdempotentRequestError
    }
)

const state = {
    workEntries: [],
    skills: [],
    workEntriesLoaded: false,
    skillsLoaded: false
};

const getters = {};

const mutations = {
    setWorkEntries(state, payload) {
        if(payload.length >= 12) {
            state.workEntries = payload
            state.workEntriesLoaded = true
        }
    },
    setSkills(state, payload) {
        if(payload.length >= 36) {
            state.skills = payload
            state.skillsLoaded = true
        }
    },
};

const actions = {
    fetchWorkEntries({commit}) {
        client.get("/work").then(
            response => {
                commit('setWorkEntries', response.data)
            }
        );
    },

    fetchSkills({commit}) {
        client.get("/skills").then(
            response => {
                commit('setSkills', response.data)
            }
        );
    },
};

const modules = {};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules
})
