import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MusicView from '../views/MusicView.vue'
import CVView from '../views/CVView.vue'
import AboutMeView from '../views/AboutMeView.vue'
import AboutSiteView from '../views/AboutSiteView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'BD  --> Home' }
  },
  {
    path: '/music',
    name: 'music',
    component: MusicView,
    meta: { title: 'BD  --> Music' }
  },
  {
    path: '/cv',
    name: 'cv',
    component: CVView,
    meta: { title: 'BD  --> CV' }
  },
  {
    path: '/about/me',
    name: 'aboutme',
    component: AboutMeView,
    meta: { title: 'BD  --> About Me' }
  },
  {
    path: '/about/site',
    name: 'aboutsite',
    component: AboutSiteView,
    meta: { title: 'BD  --> About Site' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
